/* This stylesheet generated by Transfonter (https://transfonter.org) on August 23, 2017 4:44 PM */

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-Light.eot');
  src:
    local('Segoe UI Light'),
    local('SegoeUI-Light'),
    url('assets/fonts/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI-Light.woff') format('woff'),
    url('assets/fonts/SegoeUI-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-BoldItalic.eot');
  src:
    local('Segoe UI Bold Italic'),
    local('SegoeUI-BoldItalic'),
    url('assets/fonts/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI-BoldItalic.woff') format('woff'),
    url('assets/fonts/SegoeUI-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-SemiBold.eot');
  src:
    local('Segoe UI Semibold'),
    local('SegoeUI-SemiBold'),
    url('assets/fonts/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI-SemiBold.woff') format('woff'),
    url('assets/fonts/SegoeUI-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI.eot');
  src:
    local('Segoe UI'),
    local('SegoeUI'),
    url('assets/fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI.woff') format('woff'),
    url('assets/fonts/SegoeUI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-Bold.eot');
  src:
    local('Segoe UI Bold'),
    local('SegoeUI-Bold'),
    url('assets/fonts/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI-Bold.woff') format('woff'),
    url('assets/fonts/SegoeUI-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-Italic.eot');
  src:
    local('Segoe UI Italic'),
    local('SegoeUI-Italic'),
    url('assets/fonts/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/SegoeUI-Italic.woff') format('woff'),
    url('assets/fonts/SegoeUI-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src:
    local('Segoe UI Black'),
    local('SegoeUI-Black'),
    url('assets/fonts/SegoeUI-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
